<script>
export default {
    name: "Helpers",
    methods: {
        getAge: function (birthday) {
            if (birthday === '') {
                return '';
            }

            birthday = this.$moment(new Date(birthday)).format('YYYYMMDD');

            const today = new Date();
            const todayDate = (today.getFullYear() * 10000) + ((today.getMonth() + 1) * 100) + today.getDate() ;

            return Math.floor((todayDate - birthday) / 10000);
        },
        nvl: function (value) {
            return value === null ? '' : value;
        }
    }
}
</script>
