<template>
    <div>
        <h5 class="text-center">{{ date | moment('YYYY年MM月') }}</h5>
        <table class="table mx-auto" style="max-height: 50px; max-width: 50px">
            <tbody>
            <tr>
                <th v-for="(day,index) in days" :key="index" class="text-center">{{ day }}</th>
            </tr>
            <tr v-for="(week,index) in calendars" :key="index">
                <td v-for="(day,index) in week"
                    :key="index"
                    class="textGray text-center">
                        <div :class="[dateExist(day) ? 'is-exist': 'not-exist' ]">
                            {{ monthCheck(day) ? day.date : '' }}
                        </div>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import moment from "moment";

export default {
    name: "Calendar",
    props: {
        date: {
            type: String,
            require: true,
            default: moment()
        },
        dailyReportDates: {
            type: Array,
            require: true,
            default: () => {
            },
        }
    },
    data() {
        return {
            currentDate: this.date,
            days: ['日', '月', '火', '水', '木', '金', '土']
        };
    },
    methods: {
        getStartDate: function () {
            let date = moment(this.currentDate).startOf("month");
            const youbiNum = date.day();
            return date.subtract(youbiNum, "days");
        },
        getEndDate: function () {
            let date = moment(this.currentDate);
            date.endOf("month");
            const youbiNum = date.day();
            return date.add(6 - youbiNum, "days");
        },
        getCalendar: function () {
            let startDate = this.getStartDate();
            const endDate = this.getEndDate();
            const weekNumber = Math.ceil(endDate.diff(startDate, "days") / 7);

            let calendars = [];
            for (let week = 0; week < weekNumber; week++) {
                let weekRow = [];
                for (let day = 0; day < 7; day++) {
                    console.log()
                    weekRow.push({
                        date: startDate.get("date"),
                        month: startDate.get('month'),
                    });
                    startDate.add(1, "days");
                }
                calendars.push(weekRow);
            }
            return calendars;
        },
    },
    computed: {
        calendars: function () {
            return this.getCalendar();
        },
        dateExist: function () {
            return function (day) {
                let date = new Date(this.date);
                let formatDate = date.getFullYear() + '-' + ('00' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + day.date).slice(-2);

                if (date.getMonth() !== day.month) return false;

                return this.dailyReportDates.includes(formatDate);
            }
        },
        monthCheck: function () {
            return function (day) {
                let date = new Date(this.date);

                return date.getMonth() === day.month;
            }
        }
    },
}
</script>

<style src="./Calender.scss" lang="scss" scoped/>
