<template>
    <div class="container-fluid w-auto" v-if="doctorReport">
        <div class="text-right mb-2">
            <button class="btn btn-info" @click="printReport">印刷</button>
        </div>
        <CCard id="printable">
            <CCardBody class="print">
                <div class="row">
                    <div class="col-4"></div>
                    <div class="col-4">
                        <h3 class="text-center">訪問看護報告書</h3>
                    </div>
                    <div class="col-4">
                        <p class="pt-2 h5">
                            施設名：{{ doctorReport.relationships.person.relationships.facility.attributes.name }}
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <table class="table table-bordered h5">
                            <tbody>
                            <tr>
                                <td colspan="1" class="label">利用者氏名</td>
                                <td colspan="5">{{ doctorReport.relationships.person.attributes.name }}様</td>
                            </tr>
                            <tr>
                                <td colspan="1" class="label">生年月日</td>
                                <td colspan="2">
                                    {{ doctorReport.relationships.person.attributes.birthday | moment('YYYY年MM月DD日') }}
                                </td>
                                <td colspan="1" class="label">年齢</td>
                                <td colspan="2">{{ getAge(doctorReport.relationships.person.attributes.birthday) }}歳</td>
                            </tr>
                            <tr>
                                <td colspan="1" class="label">要介護・障害認定の状況</td>
                                <td colspan="5">{{ doctorReport.relationships.person.attributes.remark }}</td>
                            </tr>
                            <tr>
                                <td colspan="1" class="text-center">住所</td>
                                <td colspan="5">{{ doctorReport.relationships.person.attributes.address }}</td>
                            </tr>
                            <tr>
                                <td colspan="1" class="label">訪問日</td>
                                <td colspan="5">
                                    <div class="row">
                                        <div class="col-xl-6 p-4">
                                            <calendar :date="getFirstCalenderDate(doctorReport.attributes.regist_date)"
                                                      :dailyReportDates="dailyReportDates">
                                            </calendar>
                                        </div>
                                        <div class="col-xl-6 p-4">
                                            <calendar :date="getSecondCalenderDate(doctorReport.attributes.regist_date)"
                                                      :dailyReportDates="dailyReportDates">
                                            </calendar>
                                        </div>
                                        <div class="col-12 pl-4">
                                            <p>太文字の日が、訪問日となっております。</p>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="1" class="label">病状の経過</td>
                                <td colspan="5" class="condition-progress">{{ doctorReport.attributes.progress }}</td>
                            </tr>
                            <tr>
                                <td colspan="1" class="label">看護内容</td>
                                <td colspan="5" class="care-detail">{{ doctorReport.attributes.care_detail }}</td>
                            </tr>
                            <tr>
                                <td colspan="1" class="label">特筆すべき事項</td>
                                <td colspan="5" class="remark">{{ doctorReport.attributes.remark }}</td>
                            </tr>
                            <tr>
                                <td colspan="1" class="label">作成者</td>
                                <td colspan="3">{{ $root.loginUser.attributes.name }}</td>
                                <td colspan="2">
                                  <span class="d-flex justify-content-between">
                                      <span class="nurse">
                                          <span v-if="$root.loginUser.attributes.nurse === 0">✔︎ </span>
                                          看護師
                                      </span>
                                      <span class="health-practitioner">
                                          <span v-if="$root.loginUser.attributes.nurse === 1">✔︎ </span>
                                          保健師
                                      </span>
                                  </span>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 h5">
                        <p>上記のとおり、看護サービスの提供の実施について報告いたします。</p>
                    </div>
                </div>
                <div class="bottom-block">
                    <div class="row">
                        <div class="col-12 h5">
                            <p>{{ doctorReport.attributes.regist_date | moment('YYYY年MM月DD日') }}</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 h5">
                            <h5 class="p-3" v-if="doctorReport.attributes.hospital_name">
                                {{ doctorReport.attributes.hospital_name }}殿
                            </h5>
                            <h5 class="p-3" style="margin-left: 8.5rem" v-else>殿</h5>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6"></div>
                        <div class="col-6">
                            <p class="border-bottom h5">事業所名：{{ office.attributes.name }}</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6"></div>
                        <div class="col-6">
                            <p class="border-bottom h5">管理者氏名：{{ office.attributes.manager_name }}</p>
                        </div>
                    </div>
                </div>
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
import Calendar from "@/components/Calendar/Calendar";
import ApiRouter from "@/components/Systems/ApiRouter";
import ErrorHandler from "@/components/Systems/ErrorHandler";
import Helpers from "@/components/Systems/Helpers";

export default {
    name: "DisplayHomeNursingReport",
    components: {Calendar},
    mixins: [ApiRouter, ErrorHandler, Helpers],
    data: function () {
        return {
            doctorReport: null,
            person: null,
            facility: null,
            office: null,
            dailyReports: [],
            childrenDailyReports: [],
            dailyReportDates: []
        }
    },
    created: function () {
        this.loadDoctorReport();
    },
    methods: {
        loadDoctorReport: function () {
            axios.get(this.routes.doctorReport(this.$route.params['id']))
                .then(response => {
                    this.doctorReport = response.data.data;
                    this.person = this.doctorReport.relationships.person;
                    this.facility = this.person.relationships.facility;
                    this.office = this.facility.relationships.office;
                    this.dailyReports = this.person.relationships.dailyReports;
                    this.childrenDailyReports = this.person.relationships.childrenDailyReports;

                    this.getDailyReportDates(this.dailyReports, this.childrenDailyReports);

                })
                .catch(error => {
                    this.handleErrorStatusCode(error);
                });
        },
        getDailyReportDates: function (dailyReports, childrenDailyReports) {
            if (dailyReports.length >= childrenDailyReports.length) {
                dailyReports.forEach(dailyReport => {
                    this.dailyReportDates.push(dailyReport.attributes.register_date);
                });
            } else {
                childrenDailyReports.forEach(childrenDailyReport => {
                    this.dailyReportDates.push(childrenDailyReport.attributes.register_date);
                });
            }
        },
        printReport: function () {
            window.document.body.innerHTML = document.getElementById('printable').innerHTML;
            window.print();
            this.$router.go({path: this.$router.currentRoute.path, force: true});
        }
    },
    computed: {
        getFirstCalenderDate: function () {
            return function (registDate) {
                const date = new Date(registDate);
                const year = date.getFullYear();
                let month = date.getMonth() + 1

                if ((date.getMonth() + 1) % 2 === 0) month = date.getMonth();

                return year + '-' + month + '-01';
            }
        },
        getSecondCalenderDate: function () {
            return function (registDate) {
                const date = new Date(registDate);
                const year = date.getFullYear();
                let month = date.getMonth() + 1

                if ((date.getMonth() + 1) % 2 !== 0) month = date.getMonth() + 2;

                if (month < 10) month = '0' + month;

                return year + '-' + month + '-01';
            }
        }
    }
}
</script>

<style src="./DisplayHomeNursingReport.scss" lang="scss" scoped/>
